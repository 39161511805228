<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f2c98a"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            Discover Amazing Metronic <br />with great build tools
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="text-right">
          {{ languageText }}
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
            no-caret
            right
            no-flip
          >
            <template v-slot:button-content>
              <img
                class="h-20px w-20px rounded-sm"
                :src="languageFlag || getLanguageFlag"
                alt=""
              />
            </template>
            <b-dropdown-text tag="div" class="min-w-md-175px">
              <KTDropdownLanguage
                v-on:language-changed="onLanguageChanged"
              ></KTDropdownLanguage>
            </b-dropdown-text>
          </b-dropdown>
        </div>
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              v-if="!form.isTwoFactor"
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{
                  $t("LOGIN.USERNAME")
                }}</label>
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="username"
                    ref="username"
                    v-model="form.username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t("LOGIN.PASSWORD") }}</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  {{ $t("LOGIN.BUTTON") }}
                </button>
              </div>
            </form>
            <form
              v-else
              class="form"
              novalidate="novalidate"
              @submit.stop.prevent="verify2fa()"
            >
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t("AUTHENTICATOR.ENTER_DIGIT") }}</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="number"
                    name="2fa"
                    v-model="form.verification_code"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  {{ this.$t("MODAL.OK") }}
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="form.isTwoFactor = false"
                >
                  {{ this.$t("MODAL.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, SET_LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import speakeasy from "speakeasy";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  name: "login-1",
  components: {
    KTDropdownLanguage
  },
  mixins: [globalMixin],
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        username: "",
        password: "",
        isTwoFactor: false,
        verification_code: ""
      },
      loginData: {},
      languageFlag: "",
      languageText: "",
      languages: i18nService.languages
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        username: {
          validators: {
            notEmpty: {
              message: this.$t("MODAL.FIELD_REQUIRED")
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("MODAL.FIELD_REQUIRED")
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    onLanguageChanged() {
      const language = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      });

      this.languageFlag = language.flag;
      this.languageText = language.name;
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    verify2fa() {
      const $self = this;

      // Use verify() to check the token against the secret
      var verified = speakeasy.totp.verify({
        secret: this.loginData.g_auth,
        encoding: "hex",
        token: this.form.verification_code
      });

      if (verified) {
        this.$store.dispatch(SET_LOGIN, this.loginData);
        this.$router.push({ name: "dashboard" });
      } else {
        Swal.fire({
          text: this.$t("AUTHENTICATOR.INVALID_CODE"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
          confirmButtonText: $self.$t("MODAL.OK")
        });
      }
    },
    onSubmitLogin() {
      const $self = this;
      this.fv.validate().then(function(status) {
        if (status === "Valid") {
          var username = $self.form.username;
          var password = $self.form.password;

          // clear existing errors
          $self.$store.dispatch(LOGOUT);

          // set spinner to submit button
          const submitButton = $self.$refs["kt_login_signin_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          $self.$store
            .dispatch(LOGIN, { username, password })
            // go to which page after successfully login
            .then(e => {
              if (!e.g_auth) {
                $self.$store.dispatch(SET_LOGIN, e);
                $self.$router.push({ name: "setting" });
              } else {
                $self.loginData = e;
                $self.form.isTwoFactor = true;
              }
            })
            .catch(err => {
              Swal.fire({
                text: $self.translateErrorFromCode(err),
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
                confirmButtonText: $self.$t("MODAL.OK")
              });
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        } else if (status === "Invalid") {
          Swal.fire({
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            confirmButtonText: $self.$t("MODAL.OK")
          });
        }
      });
    }
  }
};
</script>
