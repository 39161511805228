const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "ch",
      name: "中文",
      flag: process.env.BASE_URL + "media/svg/flags/034-china.svg"
    },
    {
      lang: "id",
      name: "Indonesia",
      flag: process.env.BASE_URL + "media/svg/flags/004-indonesia.svg"
    },
    {
      lang: "th",
      name: "ภาษาไทย",
      flag: process.env.BASE_URL + "media/svg/flags/238-thailand.svg"
    },
    {
      lang: "pt",
      name: "Português",
      flag: process.env.BASE_URL + "media/svg/flags/005-portugal.svg"
    },
    {
      lang: "es",
      name: "Español",
      flag: process.env.BASE_URL + "media/svg/flags/016-spain.svg"
    },
    {
      lang: "jp",
      name: "日本語",
      flag: process.env.BASE_URL + "media/svg/flags/014-japan.svg"
    },
    {
      lang: "vn",
      name: "Tiếng Việt",
      flag: process.env.BASE_URL + "media/svg/flags/220-vietnam.svg"
    },
    {
      lang: "kr",
      name: "한국어",
      flag: process.env.BASE_URL + "media/svg/flags/018-south-korea.svg"
    },
    {
      lang: "hi",
      name: "हिन्दी",
      flag: process.env.BASE_URL + "media/svg/flags/010-india.svg"
    },
    {
      lang: "te",
      name: "తెలుగు",
      flag: process.env.BASE_URL + "media/svg/flags/010-india.svg"
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  }
};

export default i18nService;
